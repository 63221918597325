<template>
  <Main>
    <div
      class="replenishment"
      v-if="translateData"
    >
      <div class="replenishment__title">{{ this.translateData.top_up }}</div>
      <div class="replenishment__amount-wrap">
        <div class="replenishment__amount-container">
          <Check />
          <div class="replenishment__amount-title">{{ this.translateData.deposit_base }}</div>
          <div class="replenishment__amount-price">$70</div>
        </div>
      </div>
      <div
        class="replenishment__card-container"
        @click="triggerPayment()"
      >
        <img
          src="@/assets/image/perfect.png"
          alt="perfect"
        />
        <div class="replenishment__card-body">
          <div class="replenishment__card-title">
            {{ this.translateData.deposit_via }} Perfect Money
          </div>
          <div class="replenishment__card-text">{{ this.translateData.commision }} 2,5%</div>
        </div>
      </div>
      <div class="replenishment__not-perfect">
        <router-link to="/dashboard/add-perfect">
          {{ this.translateData.deposit_no_pm }}
        </router-link>
      </div>
    </div>
    <form
      action="https://perfectmoney.is/api/step1.asp"
      method="POST"
      class="form-hidden"
      v-if="this.replenishmentData"
    >
      <input
        type="hidden"
        name="PAYEE_ACCOUNT"
        :value="this.replenishmentData.payee_account"
      />
      <input
        type="hidden"
        name="PAYEE_NAME"
        :value="this.replenishmentData.payee_name"
      />
      <input
        type="hidden"
        name="PAYMENT_ID"
        :value="this.replenishmentData.payment_id"
      />
      <input
        type="text"
        name="PAYMENT_AMOUNT"
        :value="this.replenishmentData.payment_amount"
        placeholder="Amount"
      >
      <input
        type="hidden"
        name="PAYMENT_UNITS"
        :value="this.replenishmentData.payment_units"
      />
      <input
        type="hidden"
        name="PAYMENT_URL"
        :value="`${getHostname}/dashboard/thank`"
      />
      <input
        type="hidden"
        name="PAYMENT_URL_METHOD"
        value="GET"
      />
      <input
        type="hidden"
        name="NOPAYMENT_URL"
        :value="`${getHostname}/dashboard/replenishment-error`"
      />
      <input
        type="hidden"
        name="NOPAYMENT_URL_METHOD"
        value="GET"
      >
      <input
        type="hidden"
        name="STATUS_URL"
        :value="this.replenishmentData.status_url"
      />
      <input
        type="submit"
        value="Proceed"
        ref="trigger"
      />
    </form>
    <RefLink />
  </Main>
</template>

<script>
import { defineComponent } from 'vue'

import Check from '@/assets/svg/check.svg?inline'

import Main from '@/templates/Main.vue'
import RefLink from '@/components/molecules/RefLink.vue'

export default defineComponent({
  name: 'Replenishment',
  data () {
    return {
      translateData: {},
      replenishmentData: null
    }
  },
  components: {
    Main,
    RefLink,
    Check
  },
  mounted () {
    this.handleLogin()
    this.translate()
  },
  computed: {
    getHostname () {
      return window.location.origin
    }
  },
  methods: {
    translate (data) {
      data = []
      data.push('deposit_via', 'deposit_no_pm', 'commision', 'deposit_base', 'top_up')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    goToThank () {
      this.$router.push('/dashboard/thank')
    },
    handleLogin () {
      this.$store.dispatch('getPayments/getTransactionId').then(
        (response) => {
          this.replenishmentData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    triggerPayment () {
      const elem = this.$refs.trigger
      elem.click()
    }
  }
})
</script>

<style lang="scss" scoped>
.replenishment {
  padding: 80px 5% 0 45px;
  color: $secondColor;

  &__title {
    font-size: 24px;
    color: #25262c;
    text-align: left;
  }

  &__amount-wrap {
    display: flex;
    justify-content: flex-start;
    padding-top: 25px;
  }

  &__amount-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 10px;
    background: #f3f4f5;
    width: 185px;
    height: 100px;
    position: relative;
    color: #25262c;
    padding: 30px;

    svg {
      position: absolute;
      top: -10px;
      right: -10px;
    }
  }

  &__amount-title {
    font-size: 18px;
  }

  &__amount-price {
    font-weight: bold;
    font-size: 36px;
  }

  &__card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 286px;
    height: 80px;
    border-radius: 10px;
    border: 1px solid #b2b4b9;
    background: #fff;
    margin: 20px auto 10px auto;
    padding: 20px 25px;

    img {
        width: 40px;
        height: 40px;
      }

    &:hover {
      cursor: pointer;
      color: #00fffe;
      transition: 0.5s;
    }
  }

  &__card-title {
    font-size: 16px;
    line-height: 18px;
    color: #25262C;
  }

  &__card-text {
    font-size: 14px;
    color: #949494;
  }

  &__not-perfect {
    margin-bottom: 100px;
    text-align: left;

    a {
      font-size: 16px;
      color: #272AE5;

      &:hover {
        text-decoration: underline;
      }
      &:active {
        text-decoration: underline;
      }
    }
  }

  &__card-body {
    text-align: left;
    padding-left: 20px;
  }
}
.form-hidden {
  display: none;
}

@media (max-width: 1200px) {
  .replenishment {
    padding: 50px 20px 0;
  }
}
@media (max-width: $break-sm) {
  .replenishment {
    padding: 80px 10px;
    width: 100%;
    background: #F5F5F5;
    height: 330px;

    &__title {
      font-size: 16px;
    }
    &__amount-wrap {
      padding-top: 15px;
    }

    &__amount-title {
      font-size: 14px;
    }

    &__amount-container {
      padding: 15px 20px;
      height: auto;
    }

    &__amount-price {
      font-size: 24px;
      line-height: 1.3;
    }

    &__card-container {
      width: 80%;
      padding: 15px;
      height: auto;
      margin: 15px 0;

      img {
        width: 40px;
        height: 40px;
      }
    }

    &__card-title {
      font-size: 14px;
      line-height: 1.3;
    }

    &__card-text {
      font-size: 12px;
    }

    &__not-perfect {
      padding-top: 0;
      a {
        font-size: 14px;
      }
    }
  }
}
</style>
